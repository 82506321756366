exports.components = {
  "component---packages-blog-gatsby-blog-core-src-templates-categories-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/categories.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-categories-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-collection-category-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/collection.category.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-collection-category-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-post-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/post.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-post-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-postgs-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/postgs.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-postgs-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-posts-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/posts.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-posts-js" */),
  "component---packages-blog-gatsby-blog-core-src-templates-tags-js": () => import("./../../../../packages/blog/gatsby-blog-core/src/templates/tags.js" /* webpackChunkName: "component---packages-blog-gatsby-blog-core-src-templates-tags-js" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-404-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/404.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-404-jsx" */),
  "component---packages-blog-gatsby-blog-pages-src-pages-contact-jsx": () => import("./../../../../packages/blog/gatsby-blog-pages/src/pages/contact.jsx" /* webpackChunkName: "component---packages-blog-gatsby-blog-pages-src-pages-contact-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-distanciuri-vakansiebi-jsx": () => import("./../../../src/pages/distanciuri-vakansiebi.jsx" /* webpackChunkName: "component---src-pages-distanciuri-vakansiebi-jsx" */),
  "component---src-pages-gmc-jsx": () => import("./../../../src/pages/gmc.jsx" /* webpackChunkName: "component---src-pages-gmc-jsx" */),
  "component---src-pages-registracia-jsx": () => import("./../../../src/pages/registracia.jsx" /* webpackChunkName: "component---src-pages-registracia-jsx" */),
  "component---src-pages-seqtorebi-jsx": () => import("./../../../src/pages/seqtorebi.jsx" /* webpackChunkName: "component---src-pages-seqtorebi-jsx" */)
}

